<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-clipboard-check-multiple
      </v-icon> Check-In Records
      <span
        v-if="(subscriptionCheck('trial'))"
        class="premium-container"
      >
        <v-icon class="premium-style">
          mdi-crown-circle
        </v-icon>
      </span>
    </h2>
    <br>
    <div v-if="subscriptionCheck('active')">
      <v-card
        shaped
        color="#CFD8DC"
      >
        <br>
        <div
          class="header"
        >
          <div class="div-2">
            <v-btn
              color="#FF3700"
              @click="showFilterDialog = true"
            >
              Filter
            </v-btn>
          </div>
          <div class="div-1">
            <download-excel
              v-if="!isFilteredRecordsFetched"
              :data="checkInRecords"
              :fields="jsonFields"
              worksheet="SwiftCheckinRecords"
              :name="getExportSheetName"
              :button_text="btnName"
            >
              <v-btn color="#37474F">
                Export
              </v-btn>
            </download-excel>
            <download-excel
              v-if="isFilteredRecordsFetched"
              :data="filteredCheckInRecords"
              :fields="jsonFields"
              worksheet="SwiftCheckinRecords"
              :name="getExportSheetName"
              :button_text="btnName"
            >
              <v-btn color="#37474F">
                Export
              </v-btn>
            </download-excel>
          </div>
          <div class="div-3">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  fab
                  dark
                  outlined
                  color="#37474F"
                  small
                  v-on="on"
                  @click="getRecordsFromServer({itemsPerPage: 10, pageStart: 0})"
                >
                  <v-icon
                    small
                    dark
                  >
                    mdi-file-multiple-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>ALL Records</span>
            </v-tooltip>
          </div>
        </div>
        <hr>
        <br>
        <v-card-title>
          <v-row>
            <v-col
              cols="12"
              lg="4"
              md="5"
              sm="12"
            >
              <v-text-field
                v-model="search"
                label="Search"
                :hint="isSearchAdded ? searchHint : ''"
                append-icon="mdi-magnify"
                persistent-hint
                outlined
                dense
                @keyup.enter="getFilteredRecordsFromServer({itemsPerPage: 10, pageStart: 0})"
                @keydown.esc="getRecordsFromServer({ itemsPerPage: 10, pageStart: 0 })"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <p
          v-if="!isSearchAdded"
          class="info-text"
        >
          <v-icon>mdi-account-hard-hat-outline</v-icon> Indicates Project Work <span class="division-style">|</span>
          <v-icon>mdi-account-wrench-outline</v-icon>Indicates Project Issue
        </p>
        <v-data-table
          v-if="!isFilteredRecordsFetched"
          :headers="headers"
          :items="checkInRecords"
          class="elevation-1"
          disable-sort
          :footer-props="{
            'items-per-page-options': [10, 20, 30]
          }"
          :loading="updateProgress"
          :server-items-length="totalRecords"
          mobile-breakpoint="100"
          light
          @pagination="getRecordsFromServer"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td
                class="more-link"
                @click="recordDetails(item.id)"
              >
                {{ item.app_user.first_name }} {{ item.app_user.last_name }}
                <v-tooltip
                  v-if="dailyWorkHrLimitReached(item)"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      color="#C62828"
                      v-on="on"
                    >
                      mdi-alert
                    </v-icon>
                  </template>
                  <span>Exceeded Daily Work Hour Limit</span>
                </v-tooltip>
              </td>
              <td>{{ item.app_user.company.name }}</td>
              <td class="my-type-style">
                <v-chip
                  outlined
                  class="type"
                  color="#37474F"
                  dark
                >
                  {{ item.type }}
                  <span v-if="item.type === 'worker'">
                    <v-icon
                      v-if="item.work_details.type === 'project-work'"
                      small
                    >mdi-account-hard-hat-outline</v-icon>
                    <v-icon
                      v-if="item.work_details.type === 'project-issue'"
                      small
                    >mdi-account-wrench-outline</v-icon>
                  </span>
                </v-chip>
              </td>
              <td>{{ item.project.name }}</td>
              <td>{{ item.checkin_time | convertToLocal }}</td>
              <td>{{ item.checkin_gate.name }}</td>
              <td>
                <font
                  v-if="item.checkout_time === null"
                  class="My-checkout-time"
                >
                  Active*
                </font>
                <font v-else>
                  {{ item.checkout_time | convertToLocal }}
                  <v-tooltip
                    v-if="item.manual_checkout === true"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        color="#C62828"
                        v-on="on"
                      >
                        mdi-account-arrow-right
                      </v-icon>
                    </template>
                    <span>Checkout Was Done Manually</span>
                  </v-tooltip>
                </font>
              </td>
              <td>
                <font
                  v-if="item.checkout_gate === null"
                >
                  N/A
                </font><font v-else>
                  {{ item.checkout_gate.name }}
                </font>
              </td>
              <td>
                <v-btn
                  class="mt-n2"
                  elevation="1"
                  fab
                  x-small
                  icon
                  color="#37474F"
                  @click="recordDetails(item.id)"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>

        <v-data-table
          v-if="isFilteredRecordsFetched"
          :headers="headers"
          :items="filteredCheckInRecords"
          class="elevation-1"
          disable-sort
          :footer-props="{
            'items-per-page-options': [10, 20, 30]
          }"
          :loading="updateProgress"
          light
          :server-items-length="totalFilteredRecords"
          mobile-breakpoint="100"
          @pagination="getFilteredRecordsFromServer"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>
                {{ item.app_user.first_name }} {{ item.app_user.last_name }}
                <v-tooltip
                  v-if="dailyWorkHrLimitReached(item)"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      color="#C62828"
                      v-on="on"
                    >
                      mdi-alert
                    </v-icon>
                  </template>
                  <span>Exceeded Daily Work Hour Limit</span>
                </v-tooltip>
              </td>
              <td>{{ item.app_user.company.name }}</td>
              <td class="my-type-style">
                <v-chip
                  outlined
                  class="type"
                  color="#37474F"
                  dark
                >
                  {{ item.type }}
                  <span v-if="item.type === 'worker'">
                    <v-icon
                      v-if="item.work_details.type === 'project-work'"
                      small
                    >mdi-account-hard-hat-outline</v-icon>
                    <v-icon
                      v-if="item.work_details.type === 'project-issue'"
                      small
                    >mdi-account-wrench-outline</v-icon>
                  </span>
                </v-chip>
              </td>
              <td>{{ item.project.name }}</td>
              <td>{{ item.checkin_time | convertToLocal }}</td>
              <td>{{ item.checkin_gate.name }}</td>
              <td>
                <font
                  v-if="item.checkout_time === null"
                  class="My-checkout-time"
                >
                  Active*
                </font>
                <font v-else>
                  {{ item.checkout_time | convertToLocal }}
                  <v-tooltip
                    v-if="item.manual_checkout === true"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        color="#C62828"
                        v-on="on"
                      >
                        mdi-account-arrow-right
                      </v-icon>
                    </template>
                    <span>Checkout Was Done Manually</span>
                  </v-tooltip>
                </font>
              </td>
              <td>
                <font
                  v-if="item.checkout_gate === null"
                >
                  N/A
                </font><font v-else>
                  {{ item.checkout_gate.name }}
                </font>
              </td>
              <td>
                <v-btn
                  class="mt-n2"
                  elevation="1"
                  fab
                  x-small
                  icon
                  color="#37474F"
                  @click="recordDetails(item.id)"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
        <div class="py-3" />
      </v-card>
      <centre-spinner
        :loading="loading"
      />
      <records-filter-dialog
        v-if="showFilterDialog"
        @closed="showFilterDialog = false"
      />
    </div>
    <div v-else>
      <v-alert
        text
        prominent
        type="warning"
        color="#006883"
        border="left"
        class="alert-style"
      >
        Upgrade to Premium Version to view the CheckIns.
      </v-alert>
    </div>
  </v-container>
</template>
<script>
  import moment from 'moment';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import RecordsFilterDialog from './RecordsFilterDialog.vue';
  import JsonExcel from 'vue-json-excel';
  import global from 'src/mixins/global';

  export default {
    name: 'CheckInRecords',
    components: {
      'centre-spinner': spinner,
      'records-filter-dialog': RecordsFilterDialog,
      'download-excel': JsonExcel,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
    },
    mixins: [global],
    data () {
      return {
        search: null,
        updateProgress: false,
        headers: [
          { text: 'Name', align: 'start', value: 'app_user.first_name' },
          { text: 'Company', value: 'company.name' },
          { text: 'Type', value: 'type' },
          { text: 'Project', value: 'project.name' },
          { text: 'Check-In At', value: 'checkin_time' },
          { text: 'Check-In Gate', value: 'checkin_gate.name' },
          { text: 'Check-Out At', value: 'checkout_time' },
          { text: 'Check-Out Gate', value: 'checkout_gate.name' },
          { text: 'More', value: 'more' },
        ],
        loading: false,
        showFilterDialog: false,
        btnName: 'Export',
        jsonFields: {
        'First Name': 'app_user.first_name',
        'Last Name': 'app_user.last_name',
        Company: 'app_user.company.name',
        Project: 'project.name',
        'Check-In Gate': 'checkin_gate.name',
        Type: 'type',
        'Check-In Time': {
          field: 'checkin_time',
          callback: (value) => {
            return moment(value).local().format('Do MMMM YYYY hh:mm A');
          },
        },
        'Check-Out Gate': 'checkout_gate.name',
        'Check-Out Time': {
          field: 'checkout_time',
          callback: (value) => {
            if (value === null || value === '') {
              return '-';
            }
            return moment(value).local().format('Do MMMM YYYY hh:mm A');
          },
        },
        'Onsite Hours': 'total_hours',
      },
      };
    },
    computed: {
      paginatedRecordsStructure () {
        return this.$store.getters['records/getCheckInRecords'];
      },
      checkInRecords () {
        const records = this.paginatedRecordsStructure;
        return records.data;
      },
      totalRecords () {
        if (Object.keys(this.paginatedRecordsStructure).length > 0) {
          return this.paginatedRecordsStructure.total;
        }
        return 0;
      },
      getExportSheetName () {
        return 'swift_checkin_records_' + moment().format('DD-MM-YYYY-hh-mm') + '.xls';
      },
      paginatedFilteredRecords () {
        return this.$store.getters['records/getFilteredCheckInRecords'];
      },
      filteredCheckInRecords () {
        const records = this.paginatedFilteredRecords;
        return records.data;
      },
      totalFilteredRecords () {
        if (Object.keys(this.paginatedFilteredRecords).length > 0) {
          return this.paginatedFilteredRecords.total;
        }
        return 0;
      },
      isFilteredRecordsFetched () {
        if (Object.keys(this.paginatedFilteredRecords).length > 0) {
          return true;
        }
        return false;
      },
      recordsFilteringParams () {
        return this.$store.getters['records/getRecordsFilteringParams'];
      },
      isSearchAdded () {
        if (this.search === null || this.search === '') {
          return false;
        }
        return true;
      },
      searchHint () {
        return `Press Enter to filter by name: '${this.search}' or press ESC to clear.`;
      },
    },
    watch: {
      isSearchAdded (val) {
        if (val === false) {
          this.getRecordsFromServer({ itemsPerPage: 10, pageStart: 0 });
        }
      },
    },
    async mounted () {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    methods: {
      async getRecordsFromServer ({ itemsPerPage, pageStart }) {
        this.updateProgress = true;
        this.search = null;
        this.$store.dispatch('records/clearFilteredCheckInRecordsTable');
        this.$store.dispatch('records/clearRecordsFilteringParams');
        await this.$store.dispatch('records/fetchPaginatedRecords', {
          params: {
            limit: itemsPerPage,
            startPage: pageStart,
          },
        }).catch(() => {
          this.updateProgress = false;
        });
        this.updateProgress = false;
      },
      async getFilteredRecordsFromServer ({ itemsPerPage, pageStart }) {
        this.updateProgress = true;
        await this.$store.dispatch('records/fetchFilteredRecordsList', {
          params: {
            firstName: this.recordsFilteringParams.firstName,
            lastName: this.recordsFilteringParams.lastName,
            checkInTime: this.recordsFilteringParams.checkInTime,
            checkOutTime: this.recordsFilteringParams.checkOutTime,
            projectId: this.recordsFilteringParams.projectId,
            projectGateId: this.recordsFilteringParams.projectGateId,
            type: this.recordsFilteringParams.type,
            max: this.recordsFilteringParams.max,
            orderBy: this.recordsFilteringParams.orderBy,
            name: this.search === '' ? null : this.search,
            limit: itemsPerPage,
            startPage: pageStart,
          },
        }).catch(() => {
          this.updateProgress = false;
        });
        this.updateProgress = false;
      },
      recordDetails (recordId) {
        this.$router.push(`records/${recordId}`);
      },
      dailyWorkHrLimitReached (checkInDetails) {
        const checkInTime = new Date(checkInDetails.checkin_time).getTime();
        if (checkInDetails.checkout_time === null) {
          const currentTime = new Date().getTime();
          var diff = currentTime - checkInTime;
          var hrDiff = Math.floor(diff / 3.6e6);
          if (checkInDetails.app_user.work_hours <= hrDiff) {
            return true;
          }
          return false;
        } else {
          const checkOutTime = new Date(checkInDetails.checkout_time).getTime();
          var totalWorkHrs = checkOutTime - checkInTime;
          var hours = Math.floor(totalWorkHrs / 3.6e6);
          if (checkInDetails.app_user.work_hours <= hours) {
            return true;
          }
          return false;
        }
      },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.records-btn {
  margin-top: 10px;
}
.header {
  display: inline-block;
  width: 100%;
}
.div-1 {
  margin-top: 15px;
  float: right;
  margin-bottom: 10px;
}
.div-2 {
  margin-top: 15px;
  float: right;
}
.div-3 {
  margin-top: 15px;
  float: right;
  margin-right: 15px;
}
.My-checkout-time {
  color:#2E7D32;
  font-weight: bold;
}
.info-text {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  font-style: italic;
  color: #37474F;
  margin-left: 15px;
  margin-top: -20px;
  padding-bottom: 10px;
}
.division-style {
  margin-left: 5px;
  margin-right: 5px;
}
.my-type-style {
  text-transform: capitalize;
}
.more-link{
  cursor: pointer;
}
.premium-style {
  font-size:25px;
  color:#006883!important;
  font-weight:bold;
}
.premium-container{
  border-radius: 50%;
}
.alert-style {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
</style>
